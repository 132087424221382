<template>
  <div class="election-results" :class="{ 'show-all': selectedCityName == '' }" @click="mobileTooltipTrigger">
    <div v-if="!loading">
      <div class="form-wrap pt-3 pb-lg-4">
        <b-container class="">
          <b-row>
            <b-col class="text-white pb-1">
              <h1 class="mb-0">
                חיזוי תוצאות הבחירות לכנסת ה-25
                <span class="d-none d-xl-inline-block">|</span>
                <span class="d-none d-xl-inline-block mr-2">
                  ז' בחשוון התשפ"ג,
                  1 בנובמבר
                  2022
                </span>
              </h1>
              <div class="mb-2 mt-1 d-none d-xl-block">
                עודכן לאחרונה ב:
                <span>{{ lastUpdated }}</span>
              </div>
              <div class="d-block d-xl-none py-1">
                ז' בחשוון התשפ"ג,
                1 בנובמבר
                2022
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-12 col-lg-5 position-relative mb-3 mb-lg-0">
              <b-form-select id="city-select" :value="cityView" :options="options" @change="toggleCityView">
              </b-form-select>
              <i class="position-absolute fas fa-caret-down position-relative"
                style="left: 30px;top:17px;pointer-events:none">
              </i>
            </b-col>
            <b-col class="col-12 col-lg-5" v-if="cityView">
              <TypeAhead @run-search="citySelected" :placeholder="'תוצאות לפי עיר'" :label="''"
                :typeAheadFunc="getCityTypeAheads" :allCities="displayAllCities" :searchTerm="selectedCityName"
                :forceHebrew=true :searchId="'city-input-dd'" />
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="all-results text-lg-center">
        <b-container class="results-container">
          <b-row class="align-items-center">
            <b-col lg="4" class="py-2 first-top-col">
              <span class="d-lg-block ml-1 ml-lg-0">קולות שנספרו עד כה</span>
              <strong>
                {{ countedVotes.toLocaleString() }}
                <span v-if="countedVotes > 0"></span>
                <!--(בערך {{countedVotesPercent}}%)-->
              </strong>
            </b-col>
            <b-col lg="4" class="middle-top-col py-2 py-lg-0">
              <span class="d-lg-block ml-2 ml-lg-0">סך קולות צפויים</span>
              <strong>{{ eligibleVoters.toLocaleString() }}</strong>
              <small class="d-block">({{ estimatedVotesPrec.toLocaleString() }}% מסך בעלי זכות בחירה)</small>
            </b-col>
            <b-col lg="4" class="py-2 py-lg-0 last-top-col">
              <span class="d-lg-block ml-2 ml-lg-0">
                אחוז קולות שנספרו
                <small class="d-lg-block ml-1 ml-lg-0">(מתוך סך הקולות הצפויים)</small>
              </span>
              <strong>{{ estimatedVotes }}%</strong>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container class="results-table-wrap">
        <b-table responsive small :items="resultsByParty" :fields="fields">
          <template v-slot:head(est_mandates_icon)="data">
            <span v-html="data.label"></span>
            <i class="fas fa-info-circle mr-2" id="tooltip-target"></i>
            <small>(חיזוי)</small>
            <b-tooltip target="tooltip-target" ref="tooltip" :triggers="isMobile ? 'focus click' : 'hover'"
              variant="light">
              החיזוי על בסיס חישוב פונקציית הטרנספורמציה מתוצאות הבחירות הקודמות לתוצאות הבחירות הנוכחיות בקלפיות שכבר
              נספרו. חישוב המנדטים לוקח בחשבון אחוז החסימה, הסכמי עודפים ושיטת בדר-עופר.
              <br>
              <i class="fas fa-sort-up position-relative" style="top:3px;" /> = המפלגה קרובה לתוספת מנדט.
              <br>
              <i class="fas fa-sort-down position-relative" style="top:-3px;" /> = המפלגה קרובה לאיבוד מנדט.
            </b-tooltip>
          </template>
          <template v-slot:head(count_mandates)="data">
            <span v-html="data.label"></span>
          </template>
          <template v-slot:head(count_prec)="data">
            <span v-html="data.label"></span>
          </template>
          <template v-slot:head(est_prec)="data">
            <span v-html="data.label"></span>
          </template>
          <template #cell(est_mandates_icon)="data">
            <div v-if="eligibleVoters > 0">
              {{ data.item.est_mandates }}
              <i class="fas fa-sort-up position-absolute" style="color:#529108"
                v-if="data.item.est_direction === '+'"></i>
              <i class="fas fa-sort-down position-absolute" style="color:#ff2700"
                v-if="data.item.est_direction === '-'"></i>
            </div>
          </template>
          <template #cell(graph)="data">
            <div class="progress mx-3">
              <div class="progress-bar" role="progressbar"
                :style="{ width: data.item.est_prec / highestVal * 100 + '%' }" aria-valuemin="0" aria-valuemax="100">
              </div>
            </div>
          </template>
        </b-table>
      </b-container>
      <b-container class="position-relative mb-5">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          fade
          :interval="9000"
          background="#E8F6FF"
          indicators
        >
          <div class="pt-4 pr-3 pr-lg-5"><strong class="pr-lg-5">הידעת?</strong></div>
          <b-carousel-slide
            v-for="(txt, i) in generalData.hayadata"
            :key="i"
          >{{txt}}</b-carousel-slide>
        </b-carousel>
      </b-container>
    </div>
    <b-container class="text-center pt-3" v-else>
      טוען נתונים...
      <Spinner class="mt-4"></Spinner>
    </b-container>
  </div>
</template>

<script>
function gtag() { window.dataLayer.push(arguments) }
import TypeAhead from '@/components/TypeAhead'
import Spinner from '@/components/spinner'
import axios from 'axios'

//const ELECTIONS_SERVER = '/api'
const ELECTIONS_SERVER = 'https://files.dicta.org.il'
// Some of these headers are meant for the server side and not the client, but the ones that do work when used on the client
// prevent caching even when it makes sense.
// In particular, it doesn't even allow the client to revalidate the old JSON when nothing has changed, but the huge file
// has to be downloaded again.
// axios.defaults.headers = {
//   'Access-Control-Allow-Origin': '*',
//   'Accept': 'application/json',
//   'Content-Type': 'application/json',
//   'Cache-Control': 'no-store',
//   'Pragma': 'no-cache',
//   'Expires': '0',
// }
const axiosInstance = axios.create({ timeout: 60000 })
let interval = null

export default {
  name: 'SearchCities',
  components: {
    TypeAhead, Spinner
  },
  data() {
    return {
      cacheKey: null,
      loading: false,
      nationalData: {},
      cityData: [],
      generalData: {},
      eligibleVoters: 0,
      estimatedVotesPrec: 0,
      countedVotesPercent: 0,
      countedVotes: 0,
      estimatedVotes: 0,
      lastUpdated: '',
      resultsByParty: [],
      fields: [],
      slide: 0,
      cityView: false,
      options: [
        { value: false, text: 'תוצאות ארציות' },
        { value: true, text: 'תוצאות לפי יישובים' },
      ],
      selectedCityName: '',
      isMobile: window.matchMedia('(max-width: 1042px)').matches
    }
  },
  beforeDestroy() {
    window.clearInterval(interval)
  },
  mounted() {
    if (this.$route.query.city) {
      this.selectedCityName = this.$route.query.city
      this.cityView = true
    }
    this.$nextTick(function () {
      this.getKey()
    })
    interval = setInterval(this.getKey, 60000)
  },
  methods: {
    getKey() {
      axiosInstance.get(ELECTIONS_SERVER + '/elections25_cachecontrol.txt').then(response => {
        if (response.data !== this.cacheKey) {
          this.cacheKey = response.data
          if (!this.loading)
          this.getData()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    loadFile(file) {
      return axiosInstance.get(ELECTIONS_SERVER + file + '?t=' + this.cacheKey)
    },
    getData() {
      this.loadFile('/elections25_national.json').then(response => {
        this.loading = true
        this.nationalData = response.data
        // if (!this.cityView) this.setInitialData()
        this.loadFile('/elections25_city.json').then(response => {
          this.cityData = response.data
          this.loadFile('/elections25_general.json').then(response => {
            this.generalData = response.data
            this.lastUpdated = this.generalData.last_gov_update
            this.$emit('last-update-refresh', this.lastUpdated)
            this.loading = false
            this.setInitialData()
          }).catch(error => {
            console.log(error)
            this.loading = false
          })
          //if (this.cityView) this.setInitialData()
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    getHebrewDate() {
      var hebrewDate = require("hebrew-date")
      let hebrewDateToday = hebrewDate(new Date())
      const hebrewDays = [
        'א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט',
        'י', 'י"א', 'י"ב', 'י"ג', 'י"ד', 'ט"ו', 'ט"ז', 'י"ז', 'יח',
        'יט', 'כ', 'כא', 'כב', 'כג', 'כד', 'כה', 'כו', 'כז', 'כח', 'כט', 'ל'
      ]
      const hebrewMonths = [
        "אלול",
        "תשרי",
        "חשון",
        "כסלו",
        "טבת",
        "שבט",
        "אדר",
        "ניסן",
        "אייר",
        "סיון",
        "תמוז",
        "אב"
      ]
      return hebrewDays[hebrewDateToday.date - 1] + "' ב" + hebrewMonths[hebrewDateToday.month - 1] + " התשפ״א"
    },
    setInitialData() {
      if (this.cityView) {
        let found
        if (this.selectedCityName !== '')
          found = this.cityData.filter(item => { return item.name === this.selectedCityName })
        else {
          found = this.cityData.filter(item => { return item.name === this.cityData[0].name })
          this.selectedCityName = this.cityData[0].name
        }

        this.citySelected(found[0])
      }
      else
        this.citySelected(this.nationalData)
      this.fields = [
        {
          key: 'name',
          label: 'שם הרשימה',
          sortable: false,
          thClass: 'rounded-right-top',
          class: 'pr-3 text-right'
        },
        {
          key: 'letters',
          label: 'אותיות הרשימה',
          sortable: false,
          class: 'text-center'
        },
        {
          key: 'count_mandates',
          label: 'מנדטים <small>(לפי קולות שנספרו)</small>',
          sortable: false,
          class: 'text-center mandates'
        },
        {
          key: 'count_prec',
          label: 'אחוז קולות <small>(מתוך קולות שנספרו)</small>',
          sortable: false,
          class: 'text-center',
          formatter: (value) => {
            //return value + '%'
            return value ? parseFloat(value).toFixed(2) + "%" : ''
          },
        },
        {
          key: 'est_mandates_icon',
          label: 'מנדטים',
          sortable: false,
          thClass: 'est-mandates',
          class: 'text-right position-relative mandates highlighted px-2 px-lg-4',
        },
        {
          key: 'est_prec',
          label: 'אחוז קולות <small>(חיזוי)</small>',
          sortable: false,
          class: 'text-right pr-4 pr-lg-5 highlighted',
          formatter: (value) => {
            return value ? parseFloat(value).toFixed(2) + "%" : ''
            //parseFloat(x).toFixed(2)+"%"
          },
        },
        { key: 'graph', label: '', class: 'highlighted' },
      ]
    },
    toggleCityView(val) {
      this.cityView = val
      if (!this.cityView) {
        this.selectedCityName = ''
        this.citySelected(this.nationalData)
      } else {
        if (!this.cityData[0]) {
          this.loading = true
          axiosInstance.get(ELECTIONS_SERVER + '/elections25_city.json').then(response => {
            this.cityData = response.data
            this.setInitialData()
            this.loading = false
          })
        } else {
          this.selectedCityName = this.cityData[0].name
          this.citySelected(this.cityData[0])
        }
      }
    },
    displayAllCities() {
      return this.cityData.filter(function (item) { return item.name })
    },
    getCityTypeAheads: async function (wordSoFar) {
      var found = this.cityData.filter(function (item) { return item.name.startsWith(wordSoFar.trim()) })
      return found
    },
    citySelected(selectedCity) {
      this.eligibleVoters = selectedCity.estimated_votes
      this.estimatedVotesPrec = selectedCity.estimated_votes_prec
      this.countedVotesPercent = selectedCity.counted_voted_prec
      this.countedVotes = selectedCity.counted_votes
      this.estimatedVotes = selectedCity.counted_est_prec
      this.resultsByParty = selectedCity.results_by_party
      //others should always be at bottom of list
      let index = this.resultsByParty.map(e => e.name).indexOf("מפלגות אחרות");
      if (this.resultsByParty.length - 1 != index) {
        this.resultsByParty.push(this.resultsByParty.splice(index, 1)[0])
      }
      if (selectedCity.name) {
        if (this.$route.query.city !== selectedCity.name)
          this.$router.push({ query: { city: selectedCity.name } })
        this.selectedCityName = selectedCity.name
        gtag('event', 'Cities Searched', {
          'event_category': 'selection',
          'event_label': selectedCity.name
        })
      } else {
        if (this.$route.query.city)
          this.$router.replace({ 'query': null })
      }
    },
    mobileTooltipTrigger() {
      if (this.isMobile) {
        if (this.$refs.tooltip.localShow)
          this.$refs.tooltip.$emit('close')
      }
    }
  },
  computed: {
    highestVal() {
      return Math.max.apply(Math, this.resultsByParty.map(function (o) { return o.est_prec; }))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
  font-size: 34px;
  font-weight: 600;

  span {
    font-weight: normal;
  }

  @media screen and (max-width: 991px) {
    font-size: 28px;
  }
}

.all-results {
  background-color: #0097F8;
  color: white;
  font-size: 22px;
  line-height: 29px;
  overflow: hidden;

  small {
    font-size: 14px;
    line-height: 17px;
  }

  @media screen and (max-width: 991px) {
    font-size: 18px;
  }
}

.first-top-col {
  background-color: #E6E6E6;
  color: black;

  @media screen and (min-width: 992px) {
    padding-top: 14px !important;
    padding-bottom: 15px !important;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 1500px;
      background-color: #E6E6E6;
      left: 100%;
      top: 0;
    }
  }
}

.middle-top-col {
  @media screen and (min-width: 992px) {
    border-right: 2px solid white;
    border-left: 2px solid white;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  @media screen and (max-width: 991px) {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
  }
}

.last-top-col {
  @media screen and (max-width: 991px) {
    line-height: 22px;
  }
}

.form-wrap {
  //background: #262470;
  background: #142D9F;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

img {
  max-width: 100%;
  max-height: 900px;
}
</style>
<style lang="scss">
.results-table-wrap {
  /* width */
  margin-top: 34px;

  .table-responsive {
    border: 1px solid #979797;
    border-radius: 8px;
    margin-bottom: 34px;
  }

  .table {
    tbody {
      tr:nth-of-type(odd) {
        background-color: #e6e6e6;
      }

      tr:last-child {
        td:first-child {
          border-bottom-right-radius: 8px;
        }
      }
    }

    th {
      background-color: #5e5d6f !important;
      color: white !important;

      &.highlighted {
        background-color: #0097F8 !important;
      }

      @media screen and (max-width: 991px) {
        &.est-mandates {
          min-width: 105px;
        }
      }

      line-height: 21px;
      vertical-align: middle !important;
      padding-top: 8px;
      padding-bottom: 8px;
      border-left: 3px solid white !important;
      border-top: none;
      font-weight: normal;

      small {
        display: block;
        white-space: nowrap;
      }

      &:last-child,
      &:nth-last-child(2) {
        border-left: none !important;
      }

      &.rounded-right-top {
        border-top-right-radius: 8px;
      }

      &.rounded-left-top {
        border-top-left-radius: 8px;
      }
    }

    td {
      font-size: 16px;
      border-left: 3px solid white !important;
      border-top: none;

      &:last-child {
        border-left: none !important;
        width: 220px;
        padding-left: 25px;

        @media screen and (max-width: 991px) {
          padding-left: 15px;
        }
      }

      &:nth-last-child(2) {
        border-left: none !important;
        width: 140px;

        @media screen and (max-width: 991px) {
          padding-left: 12px
        }
      }

      &:first-child {
        border-right: none !important;
      }

      &.highlighted {
        //background: rgba(255, 171, 58, 0.1);
        background: rgba(0, 151, 248, 0.05);
        font-size: 22px;
      }

      vertical-align: middle !important;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .fas {
      left: 20px;

      @media screen and (max-width: 991px) {
        left: 10px;
      }

      &.fa-sort-up {
        top: 16px;
      }
    }

    .progress {
      height: 14px;
      background-color: transparent;
      border-radius: 7.5px;
      width: 200px;

      .progress-bar {
        border-radius: 7.5px;
      }
    }

  }
}

.mandates {
  display: none;
}

.show-all {
  .mandates {
    display: table-cell;
  }
}

tbody tr {
  &:nth-child(1) {
    .progress-bar {
      background: #5445eb;
    }
  }

  &:nth-child(2) {
    .progress-bar {
      background: #60b3ea;
    }
  }

  &:nth-child(3) {
    .progress-bar {
      background: #3dd4d7;
    }
  }

  &:nth-child(4) {
    .progress-bar {
      background: #5ed272;
    }
  }

  &:nth-child(5) {
    .progress-bar {
      background: #c7d23a;
    }
  }

  &:nth-child(6) {
    .progress-bar {
      background: #827e00;
    }
  }

  &:nth-child(7) {
    .progress-bar {
      background: #db943e;
    }
  }

  &:nth-child(8) {
    .progress-bar {
      background: #ff9300;
    }
  }

  &:nth-child(9) {
    .progress-bar {
      background: #ef335b;
    }
  }

  &:nth-child(10) {
    .progress-bar {
      background: #dc5858;
    }
  }

  &:nth-child(11) {
    .progress-bar {
      background: #8b0192;
    }
  }

  &:nth-child(12) {
    .progress-bar {
      background: #e251d4;
    }
  }

  &:nth-child(13) {
    .progress-bar {
      background: #d6b2dc;
    }
  }

  &:nth-child(14) {
    .progress-bar {
      background: #cf8686;
    }
  }

  &:nth-child(15) {
    .progress-bar {
      background: #000000;
    }
  }
}

.carousel {
  height: auto;
  border-radius: 8px;
  font-size: 22px;

  //border: 1px solid #cfcfcf;
  .carousel-inner {
    height: 100%;

    .carousel-item {

      //top: 30px;
      //min-height: 95px;
      .carousel-caption {
        position: static;
        color: black;
        padding: 0 15px 10px;
        line-height: 27px;
        text-align: right;
        padding-right: 96px;
        padding-left: 96px;

        @media (max-width: 991px) {
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }

  .carousel-indicators {
    padding: 0 0 5px;
    position: static;

    //display: none;
    li {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      margin-right: 4px;
      margin-left: 4px;
      background-color: #5e5d6f;
      opacity: 0.55;

      &.active {
        background-color: #142D9F;
        opacity: 1;
      }

    }
  }
}

.tooltip.b-tooltip {
  opacity: 1 !important;

  .tooltip-inner {
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .14);
    background: white !important;
    text-align: right !important;
    max-width: 230px !important;
    opacity: 1 !important;
  }
}

.custom-select {
  font-size: 25px !important;
  border-color: black !important;
  border-radius: 8px !important;
  height: 51px !important;
  line-height: 31px !important;
  padding: 0 12px !important;
  background: white !important;

  option {
    cursor: pointer;

    &:checked {
      background: #d6ecff -webkit-linear-gradient(bottom, #d6ecff 0%, #d6ecff 100%);

      &:hover {
        background: #d6ecff -webkit-linear-gradient(bottom, #000000 0%, #d6ecff 100%);
      }
    }

    &:hover {
      background: #d6ecff -webkit-linear-gradient(bottom, #000000 0%, #d6ecff 100%);
    }
  }

  @media (max-width: 991px) {
    border-radius: 6px;
    height: 48px;
    line-height: 48px;

    option {
      font-size: 32px;
    }

    //font-size: 32px;
  }
}

select option {
  -webkit-text-fill-color: black;
}

select:disabled option {
  -webkit-text-fill-color: #6c757d;
}
</style>
