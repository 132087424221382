<template>
  <div id="app" dir="rtl">
    <Header :last-update="lastUpdate"/>
    <!--<div class="blur-data position-fixed w-100 h-100"></div>-->
    <SearchCities @last-update-refresh="(newDate)=>lastUpdate=newDate"/>
  </div>
</template>

<script>
import SearchCities from './components/SearchCities.vue'
import Header from './components/header.vue'

export default {
  name: 'App',
  components: {
    SearchCities,
    Header
  },
  data() {
    return {
      lastUpdate: ''
    }
  }  
}
</script>
<style lang="scss">
  @media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait){
    .container{
      max-width: 100%!important;
    }
  }
  body, #app {
    //direction: rtl;
    text-align: right;
    font-size: 18px;
    font-family: 'Arimo', sans-serif;
    color: black;
  }    
  .blur-data {
    top: 0px;
    left: 0;
    background: rgba(255, 255,255, 0.7);
    z-index: 2;
  }
  xul li:not(.autocomplete-result) {
    list-style-type: none;
    position: relative;
    padding-right: 30px;
    &::before {
      content: "\f060";
      font-family: 'Font Awesome 5 Free';
      font-weight: 900; 
      right: 0;
      position: absolute;
    }
  }
  
</style>